<template>
  <div class="container-app">
    <div class="arrow-container -expand-arrows-animation">
      <div class="arrow"></div>
      <div class="arrow"></div>
      <div class="arrow"></div>
      <div class="arrow"></div>
      <div class="arrow"></div>
    </div>

    <div class="container-quiz" v-if="!submitted">
      <div class="header-quiz">
        <h1>Basic Info</h1>
      </div>
      <div class="form-group mt-5">
        <label>Test Name</label>
        <input
          type="input"
          v-model="title"
          class="form-control mb-4"
          name="title"
        />
        <label for="startTime">Test Start Time (date and time):</label>
        <input
          type="datetime-local"
          v-model="start_time"
          class="form-control mb-4"
          name="startTime"
        />
        <label for="duration">Duration:</label>
        <input
          type="number"
          v-model="duration"
          class="form-control mb-2"
          name="duration"
          placeholder="Minutes"
        />
        <label>Check to add question via Excel</label>
        <input type="checkbox" name="manual" v-model="Excel">
     <div v-if="Excel">
        <input
          class="input-file"
          type="file"
          @change="exportData"
          accept=".xlsx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <button class="btn btn-success my-5" @click="checkFile">
          Add Question From Excel
        </button>
      </div>
      <div v-else>
          <label for="currentSectionName"
          >Section {{ current_section + 1 }} Name</label
        >
        <select
          v-model="current_section_name"
          class="form-control mb-4"
          name="currentSectionName"
        >
          <option disabled value="">Select section to add</option>
          <option v-for="(section, index) in section" :key="index">
            {{ section.section }}
          </option>
          <option value="CN5">New Manual Section</option>
        </select>
        <input
          v-if="manualSection()"
          type="text"
          v-model="manual_Section"
          name="currentSectionName"
          class="inputSection"
        />
        <button class="btn btn-primary" @click="decSection">
          Prev Section
        </button>
        <button
          v-if="!nameP && !manualSection()"
          class="btn btn-primary"
          @click="addSection"
        >
          Add Section
        </button>
        <button
          v-else-if="!nameP && manualSection()"
          class="btn btn-primary"
          @click="addNewSection"
        >
          Add Section
        </button>
        <button v-else class="btn btn-primary" @click="updateSection">
          Update Section
        </button>
        <button class="btn btn-primary" @click="incSection">
          Next Section
        </button>
        <button class="btn btn-success" @click="validate1">Next</button>
      </div>
      </div>
    </div>
    <div class="container-quiz" v-else>
      <div class="header-quiz">
        <h1>Quiz Created</h1>
      </div>
      <button class="btn btn-success my-5" @click="autoTest">
        Automated Test Creation
      </button>
      <button class="btn btn-success my-5" @click="redirect">
        Manual Test Creation
      </button>
    </div>
  </div>
</template>
<script>
import db from "../../firebase.js";
import store from "@/store";
import XLSX from "xlsx";
export default {
  name: "CreateTest",
  props: ["page_id"],
  async mounted() {
    const docs = await db.collection("section").get();
    this.section = docs.docs.map(doc => doc.data());
    var sect = db.collection("questionBank");
    sect = await sect.get();
    sect = sect.docs.map(doc => doc.data());
    this.questionBank = sect;
    store.state.loading = false;
  },
  data() {
    return {
      Excel:0,
      submitted: false,
      title: "",
      start_time: "",
      duration: "",
      test_id: "",
      current_section: 0,
      current_section_name: "",
      maxsection: 0,
      nameP: 0,
      section: [],
      questionBank: [],
      sections: [],
      questions: [],
      current_question: "",
      marks: "",
      correct: "",
      options: "",
      manual_Section: ""
    };
  },
  computed: {
    getStartDate() {
      if (this.start_time !== "") {
        return new Date(this.start_time);
      }
    }
  },
  methods: {
    redirect() {
      this.$router.push({
        name: "EditTest",
        params: {
          test_id: this.test_id,
          page_id: this.page_id,
          test_name: this.title,
          start_time: this.start_time
        }
      });
    },
    manualSection() {
      if (this.current_section_name == "CN5") return true;
      else return false;
    },
    incSection() {
      if (this.current_section < this.maxsection) {
        this.current_section++;
        this.nameP = 0;
        if (this.sections[this.current_section] != null) {
          this.current_section_name = this.sections[
            this.current_section
          ].section_name;
          this.nameP = 1;
        } else this.current_section_name = "";
      } else swal("Add More Section");
    },
    decSection() {
      if (this.current_section > 0) {
        this.current_section--;
        this.nameP = 0;
        if (this.sections[this.current_section] != null) {
          this.current_section_name = this.sections[
            this.current_section
          ].section_name;
          this.nameP = 1;
        } else this.current_section_name = "";
      } else swal("You are at the First section");
    },
    addSection() {
      if (this.current_section_name != "") {
        var di = {};
        di["id"] = this.current_section;
        di["section_name"] = this.current_section_name;
        this.sections.push(di);
        this.current_section++;
        this.maxsection++;
        this.current_section_name = "";
        console.log(this.sections);
      } else {
        swal("Section Name!='");
      }
    },
    addNewSection() {
      if (this.current_section_name != "") {
        var di = {};
        di["id"] = this.current_section;
        di["section_name"] = this.manual_Section;
        this.sections.push(di);
        this.current_section++;
        this.maxsection++;
        this.current_section_name = "";
      } else {
        swal("Section Name!='");
      }
    },
    checkFile() {
      document.querySelector(".input-file").click();
    },
    exportData(event) {
      if (!event.currentTarget.files.length) {
        return;
      }
      var f = event.currentTarget.files[0];
      const that = this;
      let curr = new Date();
      if (
        this.duration >= 1 &&
        this.title != "" &&
        this.start_time != "" &&
        this.getStartDate >= curr
      ) {
        db.collection("tests" + this.page_id)
          .add({
            title: this.title,
            start: this.getStartDate,
            duration: +this.duration,
            total: 0,
            sections: this.sections,
            deleted: 0
          })
          .then(docid => {
            this.test_id = docid.id;
            var sections = "123";
            var sec_id = 0;
            var sectionsArr = [];
            var testId = docid.id;
            var pageId = this.page_id;

            var reader = new FileReader();
            FileReader.prototype.readAsBinaryString = function(f) {
              var binary = "";
              var wb; // Read the completed data
              var outdata; // the data you need
              var reader = new FileReader();
              reader.onload = function(e) {
                // Read into Uint8Array and convert to Unicode encoding (Unicode takes two bytes)
                var bytes = new Uint8Array(reader.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                  binary += String.fromCharCode(bytes[i]);
                }
                // Next is xlsx, see the plugin api for details
                wb = XLSX.read(binary, {
                  type: "binary"
                });
                outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                // Custom method to pass data to parent component
                var question = "";
                var marks = 0;
                var id = 0;
                var explanation = "";
                var correct = "";
                var section = 0;
                var total = 0;
                var subType = 1;
                var difficulty = "";
                for (var q in outdata) {
                  id = +q;
                  question = outdata[q].question;
                  difficulty = outdata[q].difficulty;
                  marks = outdata[q].marks;
                  total += marks;
                  explanation = outdata[q].explanation;
                  section = outdata[q].section;
                  subType = 1;
                  if (sections != section) {
                    var di = {};
                    di["id"] = sec_id;
                    di["section_name"] = section;

                    sections = section;
                    sectionsArr.push(di);
                    sec_id++;
                  }
                  if (outdata[q].correct != null) {
                    correct = outdata[q].correct.toString();
                    if(outdata[q].options!=null){
                      var temp_options = outdata[q].options.split(";");
                      var options = [];
                      for (var x in temp_options) {
                        options.push({
                          props: temp_options[x].toString()
                        });
                      }
                      db.collection("tests" + pageId)
                      .doc(testId)
                      .collection("questions")
                      .add({
                        question: question,
                        marks: +marks,
                        correct: correct,
                        id: id,
                        difficulty: difficulty,
                        section: section,
                        explanation: explanation,
                        options: options,
                        subType:subType //all options
                      });
                    }
                    else{
                      subType=0;
                      db.collection("tests" + pageId)
                      .doc(testId)
                      .collection("questions")
                      .add({
                        question: question,
                        section: section,
                        marks: +marks,
                        difficulty: difficulty,
                        explanation: explanation,
                        id: id,
                        correct: correct,
                        subType:subType
                      });
                    }
                    
                  } else {
                    db.collection("tests" + pageId)
                      .doc(testId)
                      .collection("questions")
                      .add({
                        question: question,
                        section: section,
                        marks: +marks,
                        difficulty: difficulty,
                        explanation: explanation,
                        id: id,
                        subType:subType
                      });
                  }
                  db.collection("tests" + pageId)
                    .doc(testId)
                    .update({
                      total: total,
                      sections: sectionsArr
                    });
                }
              };
              reader.readAsArrayBuffer(f);
            };
            reader.readAsBinaryString(f);
            this.redirect();
          })
          .catch(error => swal(error));
      }
    },
    updateSection() {
      if (this.current_section_name != "") {
        this.sections[
          this.current_section
        ].section_name = this.current_section_name;
        this.current_section_name = "";
      } else {
        swal("from where did u get here");
      }
    },
    autoTest() {
      this.$router.push({
        name: "AutoTest",
        params: {
          test_id: this.test_id,
          page_id: this.page_id,
          test_name: this.title,
          start_time: this.start_time
        }
      });
    },
    validate1() {
      let curr = new Date();
      if (
        this.duration >= 1 &&
        this.title != "" &&
        this.start_time != "" &&
        this.getStartDate >= curr &&
        this.sections != null
      ) {
        db.collection("tests" + this.page_id)
          .add({
            title: this.title,
            start: this.getStartDate,
            duration: +this.duration,
            total: 0,
            sections: this.sections,
            deleted: 0
          })
          .then(docid => {
            this.submitted = true;
            this.test_id = docid.id;
            console.log(this.test_id);
          })
          .catch(error => swal(error));
      } else
        swal("Duration>=1 minutes and Title!='' and StartTime>CurrentTime");
    }
  }
};
</script>

<style scoped>
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  letter-spacing: 2px;
}
.container-app {
  min-height: 100%;
  position: absolute;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.25em;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 5vmin;
}
.editable-ques {
  width: 60%;
  margin-top: 5px;
}
.header-quiz {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #e7eae0;
  justify-content: center;
  align-items: center;
  background-color: #ffc4c4;
  border-radius: 10px 10px 0px 0px;
}
.container-app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.container-quiz {
  display: flex;
  min-width: 40%;
  background-color: white;
  text-align: center;
  flex-flow: column;
  border: 1px solid #ffc4c4;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.questions {
  width: 100%;
}
button {
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 2%;
}

.btn-primary {
  background-color: #ff7a7a;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}
.btn-success {
  background-color: #ff3c3c;
  border-top-color: #f88888;
  border-left-color: #d86d6d;
  border-right-color: #d86d6d;
  border-bottom-color: #bb5f5f;
  border-width: 4px;
}
.arrow {
  position: absolute;
  left: -15vmax;
  bottom: -25vmax;
  display: block;
  width: 30vmax;
  height: 30vmax;
  --arrow-scale: 1;
  background: #f74e4e;
  box-shadow: 0 0 7vmax rgba(50, 50, 50, 0.2);
  z-index: -1;
  cursor: pointer;
}
.input-file {
  display: none;
}
.-expand-arrows-animation .arrow {
  animation: expand-arrows 1s forwards;
}
.arrow:nth-child(2) {
  --arrow-scale: 2;
  background: #ff6868;
  z-index: -2;
}
.arrow:nth-child(3) {
  --arrow-scale: 3;
  background: #f77777;
  z-index: -3;
}
.arrow:nth-child(4) {
  --arrow-scale: 4;
  background: #f88e8e;
  z-index: -4;
}
.arrow:nth-child(5) {
  --arrow-scale: 5;
  background: #f09f9f;
  z-index: -5;
}
* {
  overflow-y: hidden;
}
@keyframes expand-arrows {
  0% {
    transform: scale(0) skew(-15deg, -15deg) rotate(-20deg);
  }
  100% {
    transform: scale(var(--arrow-scale)) skew(-15deg, -15deg) rotate(-20deg);
  }
}
@media only screen and (max-height: 600px) {
  .container-quiz .form-group {
    overflow: auto;
  }
}
@media only screen and (max-width: 768px) {
  .container-quiz .form-group {
    transform: scale(0.9);
  }
}
.inputSection{
  width: 90%;
}
</style>
